import React from 'react'
import one from './1.svg'
import two from './2.svg'
import three from './3.svg'
import four from './4.svg'
import five from './5.svg'
import six from './6.svg'
import seven from './7.svg'
import eight from './8.svg'
import nine from './9.svg'



import Audi from './auditorium.svg'
export default function Footer() {
    return (

        <div style={{padding:"0rem 16rem",marginTop:'1rem'}} id="navba1" href="#about-us-section"
        class="scroll-button smoothscroll paddingLobby 

        
        dn-M" >
        
        <div class="row  fotterPadding" >
        
        <div class="col text-center yellow">
        
        <a  href="https://linde.virtuallive.in/lobby.html" >
        
            <img src={one} class="imgLogo " />
            
             
            <p className="menuname">Lobby</p>
        </a>
        </div>


        
        <div class="col text-center  ">
        
        <a href="https://linde.virtuallive.in/auditorium.html"
         class=" ">
         <img src={four} 
       
          class="imgLogo disabled" />          
         
        
         <p className="menuname">Auditorium</p>
         </a>
        </div>
       
        
    
      <div class="col text-center ">
        
        <a id="navIdKnow" onclick="EndTime()"  href="https://linde-photobooth.virtuallive.in/" class="yellow disabled">
          <img src={eight} class="imgLogo disabled" /> 
       
   
     
      <p  className="menuname"> Photobooth</p>
      </a>
      </div>

      <div class="col text-center ">
        
        <a id="navIdKnow" onclick="EndTime()"  href="https://linde.virtuallive.in/gamewalk.html" class="yellow disabled">
          <img src={nine} class="imgLogo disabled" /> 
       
   
     
      <p  className="menuname"> Game Zone</p>
      </a>
      </div>


   

  
        
          </div>
        
        
        
        
        
        
        
        
        </div> 
    )
}
